<template>
  <div>
    <a @click="clicked = true;">{{ item.stop_sequence + 1 }}. {{ item.stop_name }} <span v-if="item.localized_name" class="localized-name">{{ item.localized_name }}</span></a>
    <div v-if="clicked" class="stop-details">
      <ul>
        <li><a v-bind:href="'/' + item.stop_code" class="text-link">{{ $t("results.schedule") }}</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['item'],
    data: () => {
      return {
        clicked: false,
      }
    }
  }
</script>

<style lang="scss">
  .localized-name {
    padding-left: 20px;
    margin-left: 20px;
    font-style: italic;
    color: orange;
  }
</style>
