

function addFavorite(data) {
  var codes = data.stops.split('+')
  codes.sort()

  var allOk = true;
  codes.forEach(code => {
    if (code.length != 4 || isNaN(code)) {
      allOk = false;
    }
  })

  if (!allOk) {
    return false
  }

  var item = localStorage.getItem('favoriteStops');
  var favorites

  if (item) {
    favorites = JSON.parse(item);
  } else {
    favorites = {}
  }

  if (!favorites.items) {
    favorites.items = []
  }

  // Check duplicates
  favorites.items.forEach(item => {
    if (JSON.stringify(item.codes) === JSON.stringify(codes)) {
      allOk = false;
    }
  })

  if (!allOk) {
    return false;
  }

  favorites.items.push({codes: codes, path: data.stops, name: data.name ? data.name : data.stops})

  localStorage.setItem('favoriteStops', JSON.stringify(favorites));
}

function removeFavorite(stops) {
  var codes = stops.split('+')
  codes.sort()
  codes = JSON.stringify(codes)

  var _new = [];
  for (const d of getFavorites()) {
    if (JSON.stringify(d.codes) != codes) {
      _new.push(d)
    }
  }
  localStorage.setItem('favoriteStops', JSON.stringify({'items': _new}));
}

function getFavorites() {
  var item = localStorage.getItem('favoriteStops');

  if (item) {
    return JSON.parse(item).items;
  } else {
    return []
  }
}

function isFavorite(stops) {
  var codes = stops.split('+')
  codes.sort()
  codes = JSON.stringify(codes)

  for (const d of getFavorites()) {
    if (JSON.stringify(d.codes) == codes) {
      return true
    }
  }
}

function getFavCodesFlat() {
  var favCodes = []
  getFavorites().forEach((di) => {
    di.codes.forEach((code) => {
      if (favCodes.indexOf(code) == -1) {
        favCodes.push(code)
      }
    })
  })
  return favCodes
}


export { addFavorite, removeFavorite, getFavorites, isFavorite, getFavCodesFlat }
