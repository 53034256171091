<template>
  <span>{{ this.count != undefined ? $tc("results.stops_to_go", this.count, { count: this.count }) : "" }}</span>
</template>

<script>
  export default {
    props: ['count'],
    data: function () {
      return {
        charCount: 0,
        extraCount: 0,
      }
    },
    created: function () {
      if (this.count > 5) {
        this.charCount = 5;
        this.extraCount = this.count - 5;
      } else {
        this.charCount = this.count;
        this.extraCount = 0;
      }
    }
  }
</script>
