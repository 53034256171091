<template>
  <div class="modal-mask weather" v-show="showModal" @click="close">
    <div class="modal" v-on:click.stop=""> 
      <h3>{{ $t('landing.hourly_forecast') }}</h3>
      <table>
        <tr v-for="fc in forecast" v-bind:key="fc.dt">
          <td class="datetime">{{ fc.dt.substring(11, 13) }}:00</td>
          <td class="temperature">{{ Math.round(fc.airtemp )}} &deg;C</td>
          <td style="text-align: center;">
            :{{ fc.weather.split('_night')[0].split('_day')[0] }}:
            <br />
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-play">
              <polygon points="2 10 8 10 8 2 22 12 8 22 8 14 2 14" v-bind:style="{transform: 'rotate(' + (fc.wind_from_direction + 90.0) + 'deg)'}"></polygon>
            </svg>
            <span class="side-text">{{ Math.round(fc.wind_speed) }} m/s</span>
          </td>
        </tr>
      </table>
      <p>{{ $t('landing.weatherdata_from') }}</p>
      <p style="text-align: center;" class="close" @click="close">({{ $t("landing.close") }})</p>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['showModal', 'forecast'],
    methods: {
      close() {
        this.$emit('close')
      },
    }
  }
</script>

<style lang="scss">
  .modal {
    color: #444;
    position: fixed;
    left: 30px;
    top: 100px;
    padding: 10px 10px 20px 10px;
    width: calc(100% - 80px);
    background: white;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #aaa;
    border-radius: 6px;
    max-height: calc(100vh - 200px);
    overflow: scroll;

    a {
      text-decoration: underline;
    }
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    li {
      padding: 5px 0 0 0;
    }
  }

  a, a:visited {
    &.text-link {
      color: #145414;
      text-decoration: none;
    }
  }

  .temperature {
    font-size: 20px;
  }

  h3 {
    text-align: center;
  }

  polygon {
    transform-origin: center;
  }

  .weather table span {
    margin: 0;
  }

  .weather td {
    font-size: 15px;
    text-align: center;
  }

  .weather td:nth-child(2) {
    width: 40%;
    font-size: 20px;
  }
  .weather td:nth-child(3) {
    width: 40%;
  }


</style>
