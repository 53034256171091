<template>
  <div class="modal-mask" v-show="showModal" @click="close">
    <div class="modal" v-on:click.stop="">
      <div class="route-info-wrapper">
        <div class="route-short-name" v-bind:class="statusCSS">
          <p>{{ selectedTripData.routeShortName }}</p>
        </div>
        <div class="route-info" v-bind:class="statusCSS">
          <p v-if="selectedTripData.finalStop" class="highlight">{{ $t("results.final_stop") }}</p>
          <p v-if="selectedTripData.stopsDelta !== undefined">
          {{ $t("results.distance") }}:<br>
          <span class="highlight">
          {{ $tc("results.stops_to_go", selectedTripData.stopsDelta, { count: selectedTripData.stopsDelta }) }} /
          {{ estimatedArrival[0] ? estimatedArrival[0] + $t("results.hour_abbr") : "" }} {{ estimatedArrival[1] }} min
          </span>
          </p>
          <p>
          {{ $t("results.scheduled_arrival_time") }}:<br>
          <span class="highlight">
          {{ scheduledArrivalTime.getHours() }}:{{ ("0" + scheduledArrivalTime.getMinutes()).substr(-2, 2) }}
          </span>
          </p>

          <p v-if="selectedTripData.onPreviousTrip == true">{{ $t("results.on_previous_trip") }}</p>
          <p v-if="selectedTripData.atRouteStart == true">* {{ $t("results.at_route_start") }}</p>

        </div>
      </div>


      <div class="notification-container">
        <h3>{{ $t("results.notify_header") }}</h3>
        <p>{{ $t("results.notify_description") }}</p>
        <span @click="notificationStartDelta < 10 ? ++notificationStartDelta : null" class="start-delta-plus">+</span>
        <input v-model="notificationStartDelta" class="notification-start-delta" />
        <span @click="notificationStartDelta > 3 ? --notificationStartDelta : null" class="start-delta-minus">&ndash;</span>
        <a @click="registerNotification" class="notification-subscribe">{{ $t("results.notify_subscribe") }}</a>
        <span class="notification-status">{{ notificationStatus }}</span>
        <div v-if="showNotificationDisclaimer" class="notification-disclaimer">

          <h3>{{ $t("misc.experimental_feature") }}</h3>
          <p>
          {{ $t("results.notify_disclaimer") }}
          </p>
          <p>
          <input type="checkbox" id="notify_disclaimer" v-model="notificationDisclaimerAccepted">
          <label for="notify_disclaimer">{{ $t("results.notify_disclaimer_understood") }}</label>
          </p>
          <a @click="acceptNotificationDisclaimer" class="notification-subscribe">{{ $t("results.notify_subscribe") }}</a>
          <a @click="showNotificationDisclaimer = false" class="notification-subscribe cancel">{{ $t("misc.cancel") }}</a>
        </div>
      </div>

      <h3>{{ $t("results.list_of_stops") }}</h3>

      <div class="trip-stop-times">
        <p v-if="!stopTimes.length">
        <span class="this-stop">{{ selectedTripData.stopName }} </span>
        </p>
        <template v-for="group in stopTimes">
        <ul v-bind:key="group.items[0].id" v-if="showAllStops || (group.group == 0 && !group.pastStops)">
          <li v-for="item in group.items" v-bind:key="item.id"
            v-bind:class="item.stop_code == selectedTripData.stopCode ? 'this-stop' : ''"
            class="stop-time"
            >
            <StopListItem v-bind:item="item" />
          </li>
        </ul>
        <ul v-else-if="!group.pastStops" v-bind:key="group.items[0].id">
          <li class="stop-time">
            <StopListItem v-bind:item="group.items[0]" />
          </li>
          <li v-if="group.items.length > 1">
            <span class="hidden-group">
              ...({{ group.items.length }})...
            </span>
          </li>
        </ul>
        </template>

        <p><a @click="showAllStops = true" v-if="!showAllStops" class="text-link">{{ $t("results.show_all_stops") }}</a></p>
      </div>

      <hr>

      <p>
      <a class="hide-route text-link" v-on:click="toggleRouteVisibility">{{ getAllHiddenRoutes().indexOf(selectedTripData.routeShortName) >= 0 ? $t("results.show_route") : $t("results.hide_route") }}
        (<span style="text-transform: lowercase">{{ $t("results.route") }}</span> {{ selectedTripData.routeShortName }})</a>
      </p>
      <p><a v-on:click="goToMap" class="text-link">{{ $t("results.stop_location_on_map") }}</a></p>
      <p style="text-align: center;" class="close" @click="close">({{ $t("landing.close") }})</p>
    </div>
  </div>
</template>

<script>
  import { getAllHiddenRoutes, toggleRouteVisibility } from './hidden-routes'
  import { goToMap } from './stops-loader';
  import { determineLocale } from './myi18n';
  import { getNotificationDisclaimerStatus, setNotificationDisclaimerStatus } from './misc-localconfs'
  import StopListItem from './StopListItem.vue';
  import axios from 'axios';
  import * as firebase from 'firebase/app'
  import "firebase/messaging";

  export default {
    props: ['selectedTripData', 'showModal', 'appConfig'],
    data: function () {
      return {
        stopTimes: [],
        allStopTimes: {},
        showAllStops: false,
        messaging: null,
        notificationStartDelta: 5,
        notificationStatus: '',
        showNotificationDisclaimer: false,
        notificationDisclaimerAccepted: false,
      }
    },
    computed: {
      scheduledArrivalTime: function () {
        return this.selectedTripData.scheduledArrivalTime !== undefined ? this.selectedTripData.scheduledArrivalTime : new Date()
      },
      estimatedArrival: function () {
        return this.selectedTripData.estimatedArrival !== undefined ? this.selectedTripData.estimatedArrival : [0, 0, 0]
      },
      statusCSS: function() {
        if (this.selectedTripData.stopsDelta !== undefined) {
          if (this.selectedTripData.onPreviousTrip == true) {
            return 'on-previous'
          } else if (this.selectedTripData.atRouteStart == true) {
            return 'at-route-start'
          } else {
            return 'rt'
          }
        }
        return ''
      }
    },
    components: {StopListItem},
    methods: {
      getAllHiddenRoutes() {
        return getAllHiddenRoutes()
      },
      toggleRouteVisibility() {
        toggleRouteVisibility(this.selectedTripData.routeShortName)
        this.$emit('refresh')
        this.$emit('close')
      },
      loadStops(tripId) {
        if (tripId in this.allStopTimes) {
          this.stopTimes = this.allStopTimes[tripId]
        } else {
          let app = this
          axios.get(app.appConfig.static_url + '/trip_stop_times?trip_id=' + tripId).then(function (response) {
            app.stopTimes = groupAllStops(response.data, app.selectedTripData.stopCode)
            app.allStopTimes[tripId] = app.stopTimes
          })
        }
      },
      goToMap() {
        goToMap(this, this.selectedTripData.stopCode)
      },
      close() {
        this.stopTimes = []
        this.showAllStops = false
        this.$emit('close')
        this.notificationStatus = ''
      },
      getMessaging() {
        if (this.messaging) {
          return this.messaging
        }
        firebase.initializeApp({
          apiKey: "AIzaSyDvF1XTso8cQGezRqt_tyjxviZRidh2V4A",
          authDomain: "ouli-dbeb9.firebaseapp.com",
          databaseURL: "https://ouli-dbeb9.firebaseio.com",
          projectId: "ouli-dbeb9",
          storageBucket: "ouli-dbeb9.appspot.com",
          messagingSenderId: "331629396495",
          appId: "1:331629396495:web:4946f01583ba99d37c597a"
        });
        this.messaging = firebase.messaging();

        this.messaging.onMessage((payload) => {
          console.log('message', payload)
        })

        return this.messaging
      },
      acceptNotificationDisclaimer() {
        if (this.notificationDisclaimerAccepted) {
          setNotificationDisclaimerStatus()
          this.showNotificationDisclaimer = false;
          this.registerNotification()
        }
      },
      registerNotification() {
        if (!getNotificationDisclaimerStatus()['status']) {
          this.showNotificationDisclaimer = true
          return
        }

        const messaging = this.getMessaging()

        messaging.getToken().then((currentToken) => {
          if (currentToken) {
            this.notificationStatus = '...'
            axios.post(this.appConfig.static_url + '/register_notification', {
              stop_time_id: this.selectedTripData.stopTimeId,
              token: currentToken,
              lang_code: determineLocale(),
              start_delta: this.notificationStartDelta,
            }).then((response) => {
              if (response.data.status == 'ok') {
                this.notificationStatus = 'Ok'
              } else {
                this.notificationStatus = 'Error'
              }
            })

          } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.');
            // Show permission UI.
            //updateUIForPushPermissionRequired();
            //setTokenSentToServer(false);
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          //showToken('Error retrieving Instance ID token. ', err);
          //setTokenSentToServer(false);
        });

      }
    }
  }

  function groupAllStops(data, currentCode) {
    const currentLocale = determineLocale()
    let currentCodeMet = false

    function prepare(di) {
      if (di.stop_localizations && currentLocale in di.stop_localizations) {
        di.localized_name = di.stop_localizations[currentLocale].name
      } else {
        di.localized_name = null
      }
      return di
    }

    function getGroup(di) {
      if (di.stop_code == currentCode) {
        currentCodeMet = true
        return 0
      } else if (di.stop_localizations) {
        return 0
      }
      return 1
    }

    function newGroup(di) {
      return {
        items: [di],
        group: getGroup(di),
        pastStops: !currentCodeMet,
      }
    }
    var li = [newGroup(prepare(data.shift()))]
    const lastItem = prepare(data.pop())
    let currentGroup

    data.forEach((di) => {
      di = prepare(di)
      currentGroup = li[li.length - 1]
      if (currentGroup.group == getGroup(di)) {
        currentGroup.items.push(di)
      } else {
        li.push(newGroup(di))
      }
    })

    li.push(newGroup(lastItem))

    return li
  }
</script>

<style lang="scss">
  .modal {
    color: #444;
    position: fixed;
    left: 30px;
    top: 100px;
    padding: 10px 10px 20px 10px;
    width: calc(100% - 80px);
    background: white;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #aaa;
    border-radius: 6px;
    max-height: calc(100vh - 200px);
    overflow: scroll;

    a {
      text-decoration: underline;
    }

    hr {
      border: 1px solid #D5E1D5;
    }
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .trip-stop-times {
    a {
      text-decoration: none;
    }
    ul {
      list-style: none;
      padding-left: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      li {
        padding: 5px 0 0 0;
      }
    }
    .this-stop {
      font-weight: bold;
      a {
        color: #444;
      }
    }
    .stop-details {
      font-size: 16px;
      margin-bottom: 10px;
      margin-top: 10px;
      padding-left: 10px;
    }

    .hidden-group {
      padding-left: 20px;
      font-size: 12px;
    }
  }

  .route-info-wrapper {
    display: flex;
    p {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .route-short-name {
      flex: 0 0 30%;
      font-size: 40px;
      font-weight: bold;
      color: #929292;
      text-align: center;

      &.rt {
        color: #1e7d1e;
      }
      &.on-previous, &.at-route-start {
        color: #80a680;
      }
    }

    .route-info {
      flex: 1;
      .highlight {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  a, a:visited {
    &.text-link {
      color: #145414;
      text-decoration: none;
    }
  }

  .notification-start-delta {
    font-size: 16px;
    width: 50px;
    border: 2px solid black;
    border-radius: 6px;
    text-align: center;
    height: 35px;
    margin-top: 0;
    margin-bottom: 0;
  }

  a.notification-subscribe {
    height: 35px;
    border: 1px solid green;
    border-radius: 6px;
    padding: 10px 10px 10px 10px;
    margin-left: 20px;
    text-decoration: none;
  }

  .notification-status {
    margin-left: 20px;
  }

  .start-delta-plus, .start-delta-minus {
    padding: 10px;
    font-size: 20px;
  }

  .notification-container {
    position: relative;
  }

  .notification-disclaimer {
    position: absolute;
    top: -30px;
    left: 20px;
    background: #FFF0F5;
    width: calc(100% - 60px);
    min-height: 150px;
    padding: 10px;
    padding-bottom: 20px;
    border: 1px solid black;
    border-radius: 6px;

    input {
      width: auto;
      height: auto;
      -webkit-appearance: auto;
      margin-right: 10px;
      border-radius: 2px;
    }

    .notification-subscribe {
      margin: 0px;
      background: white;
      &.cancel {
        margin-left: 10px;
      }
    }
  }


</style>
