function getAllHiddenRoutes() {
  var item = localStorage.getItem('localConfigHiddenRoutes');
  if (item) {
    return JSON.parse(item);
  }
  return [];
}

function toggleRouteVisibility(route) {
  var allHiddenRoutes = getAllHiddenRoutes()
  if (allHiddenRoutes.includes(route)) {
    allHiddenRoutes = allHiddenRoutes.filter(s => s !== route)
  } else {
    allHiddenRoutes.push(route)
  }
  localStorage.setItem('localConfigHiddenRoutes', JSON.stringify(allHiddenRoutes));
}

export { getAllHiddenRoutes, toggleRouteVisibility }
