function getNotificationDisclaimerStatus() {
  var item = localStorage.getItem('localConfigNotificationDisclaimerStatus');
  if (item) {
    return JSON.parse(item);
  }
  return {'status': false};
}

function setNotificationDisclaimerStatus() {
  localStorage.setItem('localConfigNotificationDisclaimerStatus', JSON.stringify({'status': true}));
}

export {
  getNotificationDisclaimerStatus,
  setNotificationDisclaimerStatus,
}
