<template>
  <div class="menu" v-bind:class="rootClass">
    <div class="primary-landing-link"><a href="/">Ouli.fi</a></div>
    <div class="primary-landing-link"><a href="/stops/">{{ $t("menu.stops") }}</a></div>
    <div class="expand-link"><a @click="expandClicked">
<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 24 24" width="14px" height="12px"><path d="M 0 2 L 0 4 L 24 4 L 24 2 Z M 0 11 L 0 13 L 24 13 L 24 11 Z M 0 20 L 0 22 L 24 22 L 24 20 Z"/></svg>
      </a></div>
    <div class="expand">
      <div><a href="/">Ouli.fi</a></div>
      <div><a href="/stops/">{{ $t("menu.stops") }}</a></div>
      <div><a href="/preferences/">{{ $t("menu.preferences") }}</a></div>
      <div><a href="/about/">{{ $t("menu.about") }}</a></div>
      <div><a href="/help/">{{ $t("menu.help") }}</a></div>
    </div>
  </div>
</template>

<script>

  export default {
    data() {
      return {
        expanded: false,
      }
    },
    computed: {
      rootClass: function() {
        return this.expanded ? 'expanded' : ''
      }
    },
    methods: {
      expandClicked(e) {
        e.preventDefault()
        this.expanded = this.expanded ? false : true
      }
    }
  }
</script>

<style lang="scss">
.menu {
  height: 40px;
  .expand {
    display: block;
  }
  &.expanded {
    height: auto;
    font-size: 16px;
    .expand {
      div {
        display: block;
      }
    }
    .primary-landing-link {
      display: none;
    }
  }
  .expand-link {
    a {
      padding-left: 14px;
    }
  }
}
</style>
