<template>
  <span v-if="forecast.length > 0">
    <span v-on:click="showModal = true">{{ Math.round(forecast[0].airtemp) }} &deg;C</span>
    <WeatherModal v-bind:showModal="showModal" v-bind:forecast="forecast" @close="showModal = false" />
  </span>
</template>

<script>
  import axios from 'axios';
  import WeatherModal from './WeatherModal.vue';

  export default {
    props: ['url'],
    components: {WeatherModal},
    watch: {
      url: function(newVal, oldVal) {
        if (oldVal === undefined) {
          this.getWeather(newVal)
        }
      },
    },
    data: function () {
      return {
        forecast: [],
        showModal: false,
      }
    },
    created: function () {
      if (this.url !== undefined) {
        this.getWeather(this.url)
      }
    },
    methods: {
      getWeather: function (url) {
        let app = this
        axios.get(url + '?place=oulu').then(function (response) {
          app.forecast = response.data.forecast_short
        })
      },
    }
  }
</script>
