import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n)

const messages = {
  en: {
    menu: {
      preferences: 'Preferences',
      help: 'Help',
      stops: 'Stops on map',
      about: 'About',
    },
    landing: {
      your_stops: 'Your stops',
      popular_stops: 'Popular stops',
      no_your_stops: 'You have no favourite stops. You can add a search result to your stops. Search for stops on the {landing.using_the_map_tmpl}.',
      using_the_map: 'map',
      input_placeholder: 'Stop name/number',
      close: 'close',
      slippery_warning: 'Oulu: slippery warning given at',
      hourly_forecast: 'Hourly forecast',
      weatherdata_from: 'Forecast by yr.no.',
    },
    results: {
      stops: 'Stops',
      routes: 'Routes',
      open_summary: 'Show summary',
      following_routes_are_hidden: 'The following routes are hidden',
      show_all: 'show all',
      hide_stops: 'hide routes',
      routes_hidden: 'No hidden routes | One hidden route | Routes hidden: {count}',
      stops_to_go: '0 stops | 1 stop | {count} stops',
      showing_all_routes: 'Showing all routes',
      route: 'Route',
      distance: 'Distance',
      arrival: 'Arrives',
      hour_abbr: 'h',
      retrieving: 'Retrieving stop information...',
      stop_not_found: 'Stop not found.',
      no_trips: 'No upcoming trips.',
      too_many_stops: 'Max 5 stop codes allowed.',
      scheduled_arrival_time: 'Scheduled arrival time',
      estimated_arrival: 'Estimated arrival',
      hide_route: 'Hide from results',
      show_route: 'Show in results',
      stop_location_on_map: "Show other stops near this stop",
      on_previous_trip: "On previous trip",
      at_route_start: "Waiting for the scheduled departure time at the first stop of the route",
      show_all_stops: "Show all stops",
      list_of_stops: 'List of stops',
      final_stop: "Final stop",
      schedule: "Schedule",
      notify_header: 'Notifications',
      notify_description: 'Subscribe for a notification about an approaching bus.',
      notify_subscribe: 'Subscribe',
      notify_disclaimer: 'Web Push Notifications can be delayed for multiple reasons, e.g. battery management settings, network connectivity issues etc.',
      notify_disclaimer_understood: 'I understand that notifications can be delayed, and for that reason notifications might not provide the correct status and location of the bus.',
    },
    preferences: {
      limit: 'Limit shown routes',
      limit_description: 'Hidden routes are not shown in the stop schedule. Changes are saved automatically.',
    },
    help: {
      stops_and_schedule: 'Stops and schedule',
      stops_and_schedule_p1: 'Search for stop schedule by stop name, number, or from {help.the_map}.',
      map: 'the stop map',
      stops_and_schedule_p2: 'Follow multiple stops in schedule view by typing the numbers of each stops separated by a plus sign (+), e.g. {help.multiple_stops_eg} (which would be Pekuri P and Kaupungintalo P).',
      stops_and_schedule_p3: 'Save the search result to your favourite stops from the icon left to the search box. You can later on access it easily from the front page.',
      colours: 'Colour codes',
      colours_p1: 'In schedule view the {help.green_lines_tmpl} are real time information. The {help.light_green_lines_tmpl} means that the bus on the previous trip. {help.asterix_tmpl} means that the bus is waiting for the scheduled departure time at the first stop of the route. The lines with grey colour does not have real time information related to them.',
      green_lines: 'green lines',
      light_green_lines: 'light green lines',
      asterix: 'The asterix (*)',
      hiding_routes: 'Hiding unnecessary routes',
      hiding_routes_p1: 'Hide routes from the schedule view in {help.preferences_tmpl}. Routes are hidden in all search results by default. Hidden routes can be set visible by clicking "show all".',
      preferences: 'preferences page',
    },
    about: {
      p1: 'Ouli.fi is a web application for real time schedule information in the Oulu public transport system.',
      p2: 'The application is an independent project, and is not related to the City of Oulu or the public transport department of the City of Oulu, except by the usage of the open APIs.',
      p3: 'The application uses Google Analytics and its HTTP cookies, and Google Analytics is used only to improve the application.',
      p4: 'Feedback and ideas for new features can be given publicly or privately via the Twitter account {about.twitter_link_tmpl}',
    },
    stops_on_map: {
      my_location: 'Go to my location',
    },
    misc: {
      oulu_airport: 'Oulu Airport',
      cancel: 'Cancel',
      experimental_feature: 'Experimental feature',
    },
  },
  fi: {
    menu: {
      preferences: 'Asetukset',
      help: 'Ohje',
      stops: 'Pysäkkikartta',
      about: 'Tietoja',
    },
    landing: {
      your_stops: 'Omat pysäkit',
      popular_stops: 'Suosittuja pysäkkejä',
      no_your_stops: 'Ei omia pysäkkejä. Voit tallentaa hakutuloksen omiin pysäkkeihin. Hae pysäkkejä {landing.using_the_map_tmpl}.',
      using_the_map: 'kartasta',
      input_placeholder: 'Pysäkin nimi/numero',
      close: 'sulje',
      slippery_warning: 'Oulu: liukastumisvaroitus annettu',
      hourly_forecast: 'Tuntiennuste',
      weatherdata_from: 'Sääennuste: yr.no.',
    },
    results: {
      stops: 'Pysäkit',
      routes: 'Linjat',
      open_summary: 'Näytä yhteenveto',
      following_routes_are_hidden: 'Seuraavat linjat on piilotettu',
      show_all: 'näytä kaikki',
      hide_stops: 'piilota linjat',
      routes_hidden: 'Ei linjoja piilossa | Linjoja rajattu näkyvistä 1 kpl | Linjoja rajattu näkyvistä {count} kpl',
      stops_to_go: '0 pysäkkiä | 1 pysäkki | {count} pysäkkiä',
      showing_all_routes: 'Näytetään kaikki linjat',
      route: 'Linja',
      distance: 'Etäisyys',
      arrival: 'Saapuu',
      hour_abbr: 't',
      retrieving: 'Haetaan pysäkkitietoja...',
      stop_not_found: 'Pysäkkiä ei löydy.',
      no_trips: 'Ei vuoroja.',
      too_many_stops: 'Voit antaa korkeintaan viisi pysäkkiä.',
      scheduled_arrival_time: 'Aikataulun mukainen saapumisaika',
      estimated_arrival: 'Arvioitu saapumisaika',
      hide_route: 'Piilota tuloksista',
      show_route: 'Näytä tuloksissa',
      stop_location_on_map: "Näytä muut lähellä olevat pysäkit",
      on_previous_trip: "Aiemmalla vuorolla",
      at_route_start: "Odottaa lähtöpysäkillä aikataulun mukaista lähtöaikaa",
      show_all_stops: "Näytä kaikki pysäkit",
      list_of_stops: 'Pysäkkilista',
      final_stop: "Päätepysäkki",
      schedule: "Aikataulutiedot",
      notify_header: 'Ilmoitukset',
      notify_description: 'Tilaa ilmoitus lähestyvästä bussista.',
      notify_subscribe: 'Tilaa',
      notify_disclaimer: 'Push-ilmoitukset voivat viivästyä monesta syystä, esim. laitteen akun käytön asetukset, internet-yhteyden ongelmat jne.',
      notify_disclaimer_understood: 'Ymmärrän, että ilmoitukset voivat viivästyä, ja tästä johtuen ilmoitukset eivät välttämättä näytä bussin oikeaa tilannetta ja sijaintia.',
    },
    preferences: {
      limit: 'Rajoita näytettäviä linjoja',
      limit_description: 'Piilotetut linjat eivät näy pysäkin aikataululistauksessa. Muutokset tallentuvat automaattisesti.',
    },
    help: {
      stops_and_schedule: 'Pysäkit ja aikataulutiedot',
      stops_and_schedule_p1: 'Hae aikataulutiedot pysäkin nimellä, numerolla, tai etsimällä pysäkki {help.the_map}.',
      map: 'pysäkkikartasta',
      stops_and_schedule_p2: 'Seuraa pysäkkinäkymässä useampaa pysäkkiä kirjoittamalla hakukenttään pysäkkien numerot plus-merkillä (+) eroteltuna, esim. {help.multiple_stops_eg} (eli pysäkit Pekuri P ja Kaupungintalo P).',
      stops_and_schedule_p3: 'Tallenna pysäkkihaku hakukentän vasemmalla puolella olevasta ikonista. Pääset tähän hakutulokseen myöhemmin helposti etusivulta.',
      colours: 'Väritunnisteet',
      colours_p1: 'Pysäkkinäkymässä ne tiedot, jotka ovat {help.green_lines_tmpl}, ovat reaaliaikatietoa. {help.light_green_lines_tmpl} väri tarkoittaa, että bussi on aiemmalla vuorolla. {help.asterix_tmpl} tarkoittaa, että bussi odottaa linjan lähtöpysäkillä aikataulun mukaista lähtöaikaa. Harmaalla oleville aikataulutiedoille ei ole löytynyt reaaliaikatietoa.',
      green_lines: 'vihreällä',
      light_green_lines: 'Vaalean vihreä',
      asterix: 'Tähti-merkki (*)',
      hiding_routes: 'Tarpeettomien linjojen piilotus',
      hiding_routes_p1: 'Piilota bussilinjoja näkyvistä {help.preferences_tmpl}. Linjat piilotetaan oletuksena kaikissa hakutuloksissa. Piilotetut linjat saa tarvittaessa näkyviin klikkaamalla "näytä kaikki".',
      preferences: 'asetukset-sivulla',
    },
    about: {
      p1: 'Ouli.fi on web-sovellus Oulun joukkoliikenteen aikataulutiedoille.',
      p2: 'Sovellus on itsenäinen projekti, eikä se liity Oulun kaupunkiin tai Oulun joukkoliikennejaostoon, paitsi käyttämiensä avointen rajapintojen osalta.',
      p3: 'Sovellus käyttää Google Analyticsiä ja sen HTTP-evästeitä, ja Google Analyticsiä puolestaan käytetään pelkästään sovelluksen kehittämiseen.',
      p4: 'Palautetta ja kehitysideoita voi antaa julkisesti tai yksityisesti Twitter-tilille {about.twitter_link_tmpl}',
    },
    stops_on_map: {
      my_location: 'Hae sijaintini',
    },
    misc: {
      oulu_airport: '',
      cancel: 'Peruuta',
      experimental_feature: 'Kokeellinen ominaisuus',
    },
  }
}

const dateTimeFormats = {
  'en': {
    short_dt: {
      month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
    }
  },
  'fi': {
    short_dt: {
      month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'
    }
  }
}

function determineLocale() {
  let locale = localStorage.getItem('localConfigLocale')
  let allowed = ['fi', 'en']
  let _default = 'fi'
  if (allowed.includes(locale)) {
    return locale
  }
  var userLang = navigator.language || navigator.userLanguage;
  userLang = userLang.split('-')[0].toLowerCase()
  if (allowed.includes(userLang)) {
    return userLang
  }
  return _default
}

const i18n = new VueI18n({
  locale: determineLocale(),
  messages,
  dateTimeFormats,
})

export { i18n, determineLocale }
