import axios from 'axios';
import { fromLonLat } from 'ol/proj';


function loadStops(app, cb) {
  var item = localStorage.getItem('allStops');
  if (item) {
    item = JSON.parse(item);
    if (new Date() / 1000 - item.timestamp < 600000) {
      console.log('stops from cache!');
      cb(app, item.data);
      return;
    }
  }

  axios.get(app.appConfig.static_url + '/all_stops').then(function (data) {
    localStorage.setItem('allStops', JSON.stringify({'timestamp': new Date() / 1000, 'data': data.data}));
    cb(app, data.data);
  });
}

function goToMap(_app, code) {
  loadStops(_app, function(app, stops) {
    for (var i = 0; i < stops.length; i++) {
      if (stops[i].stop_code == code) {
        var loc = fromLonLat([parseFloat(stops[i].stop_lon), parseFloat(stops[i].stop_lat)])
        window.location = '/stops/#' + loc[0] + ':' + loc[1] + ':16'
        break;
      }
    }
  })
}

function meOnMap(cb) {
  function success(position) {
    var loc = fromLonLat([position.coords.longitude, position.coords.latitude])
    cb('/stops/#' + loc[0] + ':' + loc[1] + ':16')
  }
  function error() {
    this.statusText = 'Failed to get your position.'
  }
  navigator.geolocation.getCurrentPosition(success, error)
}

export { loadStops, goToMap, meOnMap }
