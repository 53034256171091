import Vue from 'vue'
import App from './App.vue'
import MenuApp from './MenuApp.vue'
import { i18n } from './myi18n.js'

Vue.config.productionTip = false

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')

new Vue({
  i18n,
  render: h => h(MenuApp),
}).$mount('#menuapp')

function dodebug(s) {
  if (App.debug) {
    var div = document.getElementById('debug-container');
    div.innerHTML += s + '<br>'
  }
}

dodebug(history.length);
