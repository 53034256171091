<template>
  <div v-if="results.length || hiddenRoutes.length" class="results-summary-container" v-bind:class="expanded ? 'expanded' : ''">
    <div class="summary-first-line" v-on:click="$emit('toggleExpand')" v-if="!expanded">
      <span v-if="showRouteFilterControl">{{ hiddenRoutes.length ? $tc('results.routes_hidden', hiddenRoutes.length, { count: hiddenRoutes.length }): $t("results.showing_all_routes") }}</span>
      <span><a class="text-link">{{ $t('results.open_summary') }}</a></span>
    </div>

    <div v-if="expanded">

      <div v-if="showRouteFilterControl">
      <h3>{{ $t("results.routes") }}</h3>
        <p>
          {{ hiddenRoutes.length ? $tc('results.routes_hidden', hiddenRoutes.length, { count: hiddenRoutes.length }): $t("results.showing_all_routes") }}
            <span v-if="hiddenRoutes.length">
              (<a @click="$emit('refreshRouteFilter', true)" class="text-link">{{ $t("results.show_all") }}</a>)
            </span>
            <span v-else-if="routeFilterDisabled">
              (<a @click="$emit('refreshRouteFilter', false)" class="text-link">{{ $t("results.hide_stops") }}</a>)
            </span>
        </p>
        <p v-if="!routeFilterDisabled">
        {{ $t("results.following_routes_are_hidden") }}:
        {{ sortedHiddenRoutes.join(', ') }}
        </p>
      </div>

      <h3>{{ $t("results.stops") }}</h3>
      <p v-for="stop in summary.stops" v-bind:key="stop.code" class="stops">
      <span class="stop-name">{{ stop.name }}</span> ({{ stop.code }})
      <ul>
        <li>
          <a class="text-link" v-bind:href="'/' + stop.code ">{{ $t("results.schedule") }}</a>
        </li>
        <li>
          <a class="text-link" @click="goToMap(stop.code)">{{ $t("results.stop_location_on_map") }}</a>
        </li>
      </ul>
      </p>

      <p style="text-align: center;" class="close" @click="$emit('toggleExpand')">({{ $t("landing.close") }})</p>
    </div>
  </div>
</template>


<script>
  import { goToMap } from './stops-loader';
  import { getFirstNonAlpha } from './utils'

  export default {
    props: ['localConfig', 'appConfig', 'results', 'summary', 'hiddenRoutes', 'routeFilterDisabled', 'expanded'],
    computed: {
      showRouteFilterControl: function() {
        return (this.results.length || this.hiddenRoutes.length) && this.localConfig.hiddenRoutes.length && !(!this.routeFilterDisabled && !this.hiddenRoutes.length)
      },
      sortedHiddenRoutes: function() {
        const li = []
        const ret = []
        this.hiddenRoutes.forEach((n) => {
          li.push({
            route: n,
            sortKey: ("00000" + n.substr(0, getFirstNonAlpha(n))).slice(-6),
          })
        })
        li.sort((a, b) => (a.sortKey < b.sortKey ? -1 : 1))
        li.forEach((n) => {
          ret.push(n.route)
        })
        return ret
      },
    },
    methods: {
      goToMap(code) {
        goToMap(this, code)
      }
    }
  } 
</script>

<style lang="scss">
  .results-summary-container {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;

    .summary-first-line {
      text-align: center;
      span {
        margin-right: 15px;
      }
    }

    h3 {
      margin-bottom: 10px;
    }

    p {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    &.expanded {
      padding: 10px;
      padding-top: 0px;
      border: 1px solid green;
      border-radius: 6px;
    }
    ul {
      list-style: none;
      padding-left: 20px;
      margin-top: 10px;
      margin-bottom: 10px;

      li {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
</style>
