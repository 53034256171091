<template>
  <div id="app">
    <div class="language">
      <CurrentWeather v-bind:url="appConfig.weather" v-bind:style="{marginRight: '5px'}" />
      <span v-on:click="changeLanguage('en')">en</span><span v-on:click="changeLanguage('fi')">fi</span>
    </div>

    <div class="slippery-warning" v-if="warnings.slippery">
      {{ $t('landing.slippery_warning') }}
      {{ $d(warnings.slippery, 'short_dt') }}.
    </div>

    <DetailsModal :selectedTripData="selectedTripData" v-bind:showModal="showModal" v-bind:appConfig="appConfig" @close="showModal = false" v-on:refresh="refreshFromModal()" ref="detailsModal"></DetailsModal>


      <div id="code-input" v-bind:class="selectedCode ? 'code-selected' : ''">

        <button @click="addToFavoritesClicked" v-bind:class="favoriteHighlighted ? 'favorites highlighted' : 'favorites'">
          <span v-bind:style="{visibility: selectedCode ? 'visible' : 'hidden'}">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bookmark"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
          </span>
        </button>

        <form @submit="codeClicked" method="get" action="/">

          <input v-bind:value="code" v-on:input="code=$event.target.value" @focus="codeFocused" type="text" novalidate="novalidate" :placeholder="$t('landing.input_placeholder')"><button type="submit" class="submit" v-bind:disabled="searchDisabled" ref="submitButton"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-play"><polygon points="5 3 19 12 5 21 5 3"></polygon></svg></button>
          <div class="input-div" v-show="stopSearchResults.length > 0">
            <template v-for="result in stopSearchResults">
              <span v-bind:key="result[2]" v-on:click="code = result[2]; codeClicked()">{{ result[1] }}</span>
            </template>
            <div class="close" v-on:click="stopSearchResults = []">({{ $t("landing.close") }})</div>
          </div>
        </form>
      </div>

      <div id="debug-container" v-bind:style="{display: debug ? 'block' : 'none'}" style="font-size: 12px;">
      </div>

      <div class="status-text" v-if="statusText">
        {{ statusText }}
      </div>

      <div class="quick-select" v-if="!selectedCode">
        <h2>
          {{ $t("landing.your_stops") }}
        </h2>
        <template v-for="fav in favorites">
          <span v-bind:key="fav.name"><a v-bind:href="'/' + fav.path" class="bordered">{{ fav.name }}</a></span>
        </template>
        <div v-bind:style="{display: favorites.length ? 'none' : 'block'}">
        <i18n path="landing.no_your_stops" tag="p">
          <template v-slot:landing.using_the_map_tmpl>
            <a href="/stops/">{{ $t("landing.using_the_map") }}</a>
          </template>
        </i18n>
        </div>

        <h2>{{ $t("landing.popular_stops") }}</h2>
        <span><a href="/2112" class="bordered">Rotuaari</a></span>
        <span><a href="/2077" class="bordered">Pokkitörmä</a></span>
        <span><a href="/2101" class="bordered">Pekuri P</a></span>
        <span><a href="/2016" class="bordered">Kaupungintalo P</a></span>
        <span><a href="/3429" class="bordered">Pekuri E</a></span>
        <span><a href="/2087" class="bordered">Kaupungintalo E</a></span>

        <span><a href="/2015" class="bordered">Merikoskenkatu P1</a></span>
        <span><a href="/2012" class="bordered">Merikoskenkatu P2</a></span>
        <span><a href="/2014" class="bordered">Merikoskenkatu E1</a></span>
        <span><a href="/2013" class="bordered">Merikoskenkatu E2</a></span>

        <span><a href="/2015+2012" class="bordered">Merikoskenkatu P1 + P2</a></span>
        <span><a href="/2014+2013" class="bordered">Merikoskenkatu E1 + E2</a></span>

        <span><a href="/1771" class="bordered">Yliopisto E</a></span>
        <span><a href="/2646" class="bordered">Lentoasema <span class="localized-name" v-if="$t('misc.oulu_airport')">{{ $t('misc.oulu_airport') }}</span></a></span>
      </div>

      <ResultSummary
        v-bind:results="list" v-bind:summary="listSummary"
        v-bind:localConfig="localConfig" v-bind:hiddenRoutes="hiddenRoutes"
        v-bind:routeFilterDisabled="routeFilterDisabled" v-bind:appConfig="appConfig"
        v-bind:expanded="listSummaryExpanded"
        v-on:refreshRouteFilter="refreshRouteFilter"
        v-on:toggleExpand="listSummaryExpanded = !listSummaryExpanded" />

      <table class="stop-results" ref="table" v-if="list.length">
        <tr><th>{{ $t("results.route") }}</th><th>{{ $t("results.distance") }}</th><th>{{ $t("results.arrival") }}</th></tr>
        <template v-for="group in list">
          <tr v-bind:key="group[0].groupId" class="stop-name"><td colspan="3">{{ group[0].stopName }}</td></tr>
          <tr v-for="item in group" v-bind:class="item.css_class" v-bind:key="item.stopName + item.trip_id" @click="resultRowClicked(item)">
            <td>
              <div class="stop-data-route">
                <svg v-if="item.finalStop" id="i-end" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" width="22" height="20">
                  <line x1="22" y1="3" x2="22" y2="21"></line><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline>
                </svg>
                {{ item.route_short_name }}
              </div>
              <div class="side-text"></div>

            </td>
            <td>{{ item.atRouteStart ? '*' : '' }} <StopsToGo v-bind:count="item.stops_delta"></StopsToGo></td>
            <td>
              <span class="time">
                {{ item.remaining_time[0] ? item.remaining_time[0] + $t("results.hour_abbr") : "" }} {{ item.remaining_time[1] }} min
              </span>
              <br>
              <span class="side-text">
                {{ item.arrival_time.getHours() }}:{{ ("0" + item.arrival_time.getMinutes()).substr(-2, 2) }}
              </span>
            </td>
          </tr>
        </template>
      </table>

  </div>
</template>

<script>
  import Vue from 'vue';
  import axios from 'axios';
  import StopsToGo from './StopsToGo.vue';
  import DetailsModal from './DetailsModal.vue';
  import ResultSummary from './ResultSummary.vue';
  import CurrentWeather from './Weather.vue';
  import VueGtag from 'vue-gtag';
  import { addFavorite, removeFavorite, getFavorites, isFavorite } from './favorites-core'
  import { loadStops } from './stops-loader';
  import { getAllHiddenRoutes } from './hidden-routes';

  if (window.location.pathname == '/stops') {
    window.location = '/stops/' + window.location.search;
  } else if (window.location.pathname == '/preferences') {
    window.location = '/preferences/';
  } else if (window.location.pathname == '/help') {
    window.location = '/help/';
  } else if (window.location.pathname == '/about') {
    window.location = '/about/';
  }

  var debug = window.location.search.indexOf('debug') > 0 ? true : false;

  //var App = new Vue({
  export default {
    data: function () {
      return {
        debug: debug,
        list: [],
        listSummary: {},
        listSummaryExpanded: false,
        hiddenRoutes: [],
        routeFilterDisabled: false,
        timer: null,
        staticCache: {},
        code: '',
        selectedCode: null,
        searchDisabled: false,
        stopName: '',
        favoriteHighlighted: false,
        favorites: [],
        stopSearchResults: [],
        stopSearchResultsVisible: false,
        stopDataSummary: {toggleDetails: false},
        statusText: "",
        localConfig: {},
        refreshInProgress: false,
        showModal: false,
        selectedTripData: {},
        warnings: {},
        appConfig: {}
      }
    },

    watch: {
      code(code) {
        if (this.allStops === undefined) {
          return
        }

        this.stopSearchResults = [];
        if (4 > code.length) {
          console.log('exiting')
          return
        }

        var li = []

        code = code.toLowerCase()
        var app = this
        this.allStops.filter(function(item) {
          if (item[0].includes(code)) {
            li.push(item)
          }
        })

        if (li.length) {
          app.stopSearchResultsVisible = true
          app.stopSearchResults = li
        }
      }
    },

    components: {StopsToGo, DetailsModal, ResultSummary, CurrentWeather},

    created: function () {
      var app = this;
      window.onpopstate = function() {
        //window.dodebug('onpopstate..');
        //window.dodebug(window.location.pathname);
        app.clearTimer();
        var changed = app.changeStopCodeByPath(window.location.pathname)

        if (changed) {
          if (app.appConfig.ga_id) {
            app.$gtag.pageview({page_path: window.location.pathname});
          }
        } else {
          app.code = null;
          app.selectedCode = null;
          app.list = [];
          //window.dodebug('expecting to be at "/"');
        }
      };
      document.addEventListener("visibilitychange", function() {
        if (document.hidden) {
          app.clearTimer()
          app.list = []
        } else {
          app.changeStopCodeByPath(window.location.pathname);
        }
      }, false);

      loadConfig(this, function(app) {
        if (app.appConfig.ga_id) {
          Vue.use(VueGtag, {config: {id: app.appConfig.ga_id}})
          app.$gtag.pageview({page_path: window.location.pathname});
        }
        app.localConfig['hiddenRoutes'] = getAllHiddenRoutes()
        app.changeStopCodeByPath(window.location.pathname)
        app.refreshFavorites()
        getWarnings(app, (d) => {
          app.warnings = d;
        })
      });
    },
    methods: {
      changeStopCodeByPath: function(pathname) {
        pathname = pathname.substr(1, pathname.length);
        var codes = pathname.split('+')

        var allOk = true;
        codes.forEach(code => {
          if (code.length != 4 || isNaN(code)) {
            allOk = false;
          }
        })

        if (allOk) {
          this.code = pathname;
          this.selectedCode = pathname;
          this.refreshList(this.$t("results.retrieving"));
          this.timer = setInterval(this.refreshList, 1000 * 8);
          return true;
        }
        return false;
      },
      enableSearch: function() {
        this.searchDisabled = false;
      },
      clearTimer: function() {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      },
      codeClicked: function(e) {
        this.listSummaryExpanded = false;
        this.$refs.submitButton.focus();
        if (this.code) {
          this.searchDisabled = true;
          setTimeout(this.enableSearch, 2000);

          if (this.code != this.selectedCode) {
            this.selectedCode = this.code;
            var path = '/' + this.selectedCode;
            if (this.debug) {
              path += '?debug'
            }
            window.history.pushState({code: this.selectedCode}, this.selectedCode, path);
            if (this.appConfig.ga_id) {
              this.$gtag.pageview({page_path: window.location.pathname});
            }
            this.refreshFavorites()
          }

          this.list = [];
          this.hiddenRoutes = [];

          this.clearTimer();
          this.refreshList(this.$t("results.retrieving"));
          this.timer = setInterval(this.refreshList, 1000 * 8);
        }

        if (e) {
          e.preventDefault();
        }
      },
      addToFavoritesClicked: function(e) {
        if (!this.selectedCode) {
          return
        }

        if (isFavorite(this.selectedCode)) {
          removeFavorite(this.selectedCode)
        } else {
          this.loadAllStops()
          var stopsList = this.selectedCode.split('+')
          var stopNamesList = []
          var allStopsByCode = {}
          this.allStops.forEach(stop => {
            allStopsByCode[stop[2]] = stop[1]
          })
          stopsList.forEach(code => {
            stopNamesList.push(allStopsByCode[code])
          })

          if (!stopNamesList.length) {
            e.preventDefault()
            return
          }
          addFavorite({stops: this.selectedCode, name: stopNamesList.join(' + ')})
        }
        this.refreshFavorites();
        e.preventDefault();
      },
      refreshFavorites() {
        if (!this.selectedCode) {
          console.log('refreshFavorites: selectedCode not set')
          this.favorites = getFavorites()
          return;
        }
        if (isFavorite(this.selectedCode)) {
          this.favoriteHighlighted = true;
        } else {
          this.favoriteHighlighted = false;
        }
      },
      refreshList: function(statusText) {
        if (this.refreshInProgress) {
          return
        }
        var codes = this.selectedCode.split('+');

        if (codes.length > 5) {
          this.statusText = 'Voit antaa korkeintaan viisi pysäkkiä.'
          this.statusText = this.$t("results.too_many_stops")
          this.clearTimer()
          return;
        }

        var rt_url = this.appConfig.rt_url;
        var staticUrls = []
        var reqList = [axios.get(rt_url)];

        this.statusText = statusText ? statusText : ''

        codes.forEach(d => {
          var url = this.appConfig.static_url + '?stop_code=' + d
          staticUrls.push({url: url, code: d})

          if (url in this.staticCache) {
            if (new Date() / 1000 - this.staticCache[url].timestamp > 300) {
              console.log(url + ' in cache but old');
              reqList.push(axios.get(url))
            } else {
              console.log(url + ' already in cache')
            }
          } else {
            reqList.push(axios.get(url))
          }
        });

        this.refreshInProgress = true
        axios.all(reqList)
          .then(responseArr => {
            var rtData;
            var rtResponse;

            responseArr.forEach(d => {
              if (d.config.url == rt_url) {
                rtData = d.data;
                rtResponse = d;
              } else {
                this.staticCache[d.config.url] = {
                  data: d.data,
                  timestamp: new Date() / 1000,
                }
              }
            })

            this.listSummary = {
              stops: [],
            }
            var hiddenRoutes = [];
            var li = [];
            var rtTime = new Date(rtData.timestamp);
            rtTime.setSeconds(
              rtTime.getSeconds()
              + parseInt(rtResponse.headers.age !== undefined ? rtResponse.headers.age : 0));

            staticUrls.forEach(obj => {
              var staticData = this.staticCache[obj.url].data
              this.listSummary.stops.push({
                code: obj.code,
                name: staticData.stop_name,
              })

              staticData.stop_times.forEach(d => {
                //console.log(d)
                //console.log(d.trip_id)
                var dt = new Date(d.arrival_time);
                var di = {
                  stopTimeId: d.id,
                  stopName: staticData.stop_name,
                  stopCode: obj.code,
                  route_short_name: d.route_short_name,
                  arrival_time: dt,
                  time_delta: dt - rtTime,
                  stops_delta: undefined,
                  trip_id: d.trip_id,
                  finalStop: d.stop_sequence === d.all_trips[0].stops[d.all_trips[0].stops.length - 1].stop_sequence
                }

                function getCurrentStopSequence(tripId) {
                  var _rt = tripId in rtData.trips ? rtData.trips[tripId] : null;
                  if (_rt && !isNaN(_rt.vehicle.current_stop_sequence)) {
                    return _rt.vehicle.current_stop_sequence
                  }
                  return null;
                }

                /*if (d.trip_id in rtData.trips) {
                  console.log(rtData.trips[d.trip_id].vehicle.stop_id == d.all_trips[0].stops[0].stop_id)
                  console.log(rtTime - new Date(d.all_trips[0].stops[0].arrival_time))
                }*/

                var current_stop_sequence = getCurrentStopSequence(d['trip_id'])
                //var rt = d.trip_id in rtData.trips ? rtData.trips[d['trip_id']] : null
                var target_stop_sequence = parseInt(d.stop_sequence);
                var currentStopArrivalTime
                var estimate
                if (current_stop_sequence !== null) {
                  di['stops_delta'] = target_stop_sequence - current_stop_sequence;
                  currentStopArrivalTime = new Date((d.all_trips[0].stops[current_stop_sequence].arrival_time).replace(/-/g, "/"))
                  di.time_delta += (rtTime - currentStopArrivalTime) * 0.667
                } else {
                  if (d.all_trips.length >= 2 && d.all_trips[1].trip_id in rtData.trips) {
                    var prevTrip = d.all_trips[1]
                    current_stop_sequence = getCurrentStopSequence(prevTrip.trip_id)
                    if (current_stop_sequence !== null) {
                      var delta = parseInt(
                        prevTrip.stops[prevTrip.stops.length - 1].stop_sequence)
                        - current_stop_sequence
                      di['stops_delta'] = delta + target_stop_sequence
                      di['onPrevTrip'] = true;
                      di['prevTripId'] = prevTrip.trip_id
                      if (current_stop_sequence in d.all_trips[1].stops) {
                        currentStopArrivalTime = new Date(d.all_trips[1].stops[current_stop_sequence].arrival_time)
                        estimate = rtTime - currentStopArrivalTime
                        if (estimate > 0) {
                          di.time_delta += (rtTime - currentStopArrivalTime) * 0.85
                        }
                      } else {
                        console.log('current stop sequence not in previous trip stops:')
                        console.log(d)
                        console.log(current_stop_sequence)
                      }
                    }
                  } else if (
                    // Try to determine if bus is at route start
                    di.time_delta >= 0 &&
                    d.trip_id in rtData.trips &&
                    rtData.trips[d.trip_id].vehicle.stop_id == d.all_trips[0].stops[0].stop_id
                    && (rtTime - new Date(d.all_trips[0].stops[0].arrival_time)) / 1000 < 5 * 60
                  ) {
                    di.stops_delta = target_stop_sequence
                    di.atRouteStart = true
                    currentStopArrivalTime = new Date(d.all_trips[0].stops[0].arrival_time)
                    estimate = rtTime - currentStopArrivalTime
                    if (estimate > 0) {
                      di.time_delta += (rtTime - currentStopArrivalTime) * 0.75
                    }
                  } else if (di.time_delta < 0) {
                    return
                  }
                }
                    // && d.trip_id in rtData.trips && rtData.trips[d.trip_id].vehicle.stop_id == d.all_trips[0].stops[0].stop_id 


                if (di.stops_delta < 0) {
                  return;
                }
                if (di.time_delta > 0) {
                  di['remaining_time'] = secondsToString(Math.floor(di.time_delta / 1000));
                } else {
                  di['remaining_time'] = secondsToString(0);
                }

                di.css_class = 'stop-data '
                if (!isNaN(di.stops_delta)) {
                  if (di.onPrevTrip == true || di.atRouteStart) {
                    di.css_class += 'prev-trip'
                  } else {
                    di.css_class += 'rt'
                  }
                } else {
                  di.css_class += 'static'
                }

                li.push(di)
                this.stopDataSummary.rtTime = rtTime
              })


            });
            li.sort((a, b) => (score(a) < score(b) ? -1 : 1))

            var grouped = [];
            var group;
            var i = 0;
            var selectedTripDataUpdate = null;

            li.forEach(item => {
              if (!this.routeFilterDisabled && this.localConfig.hiddenRoutes.includes(item.route_short_name)) {
                if (!hiddenRoutes.includes(item.route_short_name)) {
                  hiddenRoutes.push(item.route_short_name)
                }
                return
              }

              if (grouped.length && grouped[grouped.length - 1][0].stopName == item.stopName) {
                group = grouped[grouped.length - 1]
              } else {
                group = []
                item.groupId = 'group' + (i++)
                grouped.push(group)
              }
              group.push(item)

              if (this.showModal && this.selectedTripData.tripId == item.trip_id) {
                selectedTripDataUpdate = this.getSelectedTripData(item)
              }
            });

            if (selectedTripDataUpdate) {
              this.selectedTripData = selectedTripDataUpdate
            } else if (this.showModal && this.selectedTripData.tripId !== undefined) {
              selectedTripDataUpdate = Object.assign({}, this.selectedTripData)
              selectedTripDataUpdate.passed = true
              this.selectedTripData = selectedTripDataUpdate
            }

            this.statusText = ''
            this.list = grouped;

            if (this.list.length == 0 && hiddenRoutes.length == 0) {
              this.clearTimer()
              this.statusText = this.$t("results.no_trips")
            }

            this.hiddenRoutes = hiddenRoutes
            this.refreshInProgress = false
          }).catch((e) => {
            console.log(e)
            this.clearTimer()
            this.statusText = this.$t("results.stop_not_found");
            this.refreshInProgress = false
          });
      },
      codeFocused() {
        this.loadAllStops()
      },
      loadAllStops() {
        if (this.allStops === undefined) {
          this.allStops = []
          loadStops(this, (app, stops) => {
            console.log(stops)
            stops.forEach(function(stop) {
              app.allStops.push([stop.stop_name.toLowerCase(), stop.stop_name, stop.stop_code])
            })
            app.allStops.sort(function(a, b) {
              return a[0] > b[0] ? 1 : -1
            })
          });
        }
      },
      changeLanguage(locale) {
        localStorage.setItem('localConfigLocale', locale)
        location.reload()
      },
      resultRowClicked(item) {
        this.showModal = true;
        this.selectedTripData = this.getSelectedTripData(item)
        this.$refs.detailsModal.loadStops(this.selectedTripData.tripId)
      },
      getSelectedTripData(item) {
        return {
          stopName: item.stopName,
          stopCode: item.stopCode,
          stopTimeId: item.stopTimeId,
          routeShortName: item.route_short_name,
          scheduledArrivalTime: item.arrival_time,
          estimatedArrival: item.remaining_time,
          stopsDelta: item.stops_delta,
          tripId: item.trip_id,
          onPreviousTrip: item.onPrevTrip,
          previousTripId: item.prevTrip,
          atRouteStart: item.atRouteStart,
          finalStop: item.finalStop,
        }
      },
      refreshFromModal() {
        this.routeFilterDisabled = false
        this.codeClicked()
        this.localConfig['hiddenRoutes'] = getAllHiddenRoutes()
      },
      refreshRouteFilter(b) {
        this.routeFilterDisabled = b
        this.codeClicked()
      },
    },
  };

  function score(item) {
    var base = 4
    if (!isNaN(item.stops_delta) && item.stops_delta < 4 && item.onPrevTrip !== true && item.atRouteStart !== true) {
      base = item.stops_delta
    }
    return base + item.time_delta / 10000000
  }

  function secondsToString(seconds) {
    var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
    return [numhours, numminutes, numseconds]
  }


  function loadConfig(app, cb) {
    var item = localStorage.getItem('appConfig');
    if (item) {
      item = JSON.parse(item);
      if (new Date() / 1000 - item.timestamp < 600) {
        console.log('conf from cache!');
        app.appConfig = item.data;
        return cb(app);
      }
    }

    axios.get('https://d1tenrt2p4v3f4.cloudfront.net/').then(function (data) {
      localStorage.setItem('appConfig', JSON.stringify({'timestamp': new Date() / 1000, 'data': data.data}));
      Object.keys(data.data).forEach(function(k) {
        app.appConfig[k] = data.data[k];
      })

      cb(app);
    });

  }

  function getWarnings(app, cb) {

    function prepare(data) {
      if (data.slippery) {
        data.slippery = new Date(data.slippery);
      }
      return data
    }

    var item = localStorage.getItem('localWarnings');
    if (item) {
      item = JSON.parse(item);
      if (new Date() / 1000 - item.timestamp < 1800) {
        console.log('warnings from cache!');
        return cb(prepare(item.data));
      }
    }

    axios.get(app.appConfig.static_url + '/warnings').then(function (data) {
      localStorage.setItem('localWarnings', JSON.stringify({'timestamp': new Date() / 1000, 'data': data.data}));
      cb(prepare(data.data));
    });

  }

</script>

<style lang="scss">
  h2 {
    font-size: 18px;
  }

  a, a:visited, a:active, a:hover {
    color: #444;
  }

  .quick-select {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 70px;
    overflow: hidden;

    span {
      white-space:nowrap;
      display: inline-block;
    }
    a.bordered, a.bordered:visited, a.bordered:active, a.bordered:hover {
      border: 1px solid green;
      border-radius: 6px;
      padding: 8px;
      margin-right: 20px;
      line-height: 50px;
      text-decoration: none;
      color: #444;
    }

    .localized-name {
      padding-left: 0px;
    }
  }

  #code-input {
    button.favorites {
      display: none;
    }
    form {
      padding-left: 5px;
    }
    &.code-selected {
      button.favorites {
        display: block;
      }
      form {
        padding-left: 50px;
      }
    }
  }

  button.favorites {
    width: 50px;
    font-size: 20px;
    border: none;
    margin-right: 5px;
    float: left;
    background: none;
    &.highlighted {
      span {
      font-weight: normal;
        color: green;
      }
      path {
        fill: #4CA64C;
      }
    }
    span {
      font-weight: bold;
      color: #aaa;
    }
    float: left;
    
    svg {
      margin-top: 5px;
      path {
        fill: #ccc;
      }
    }
  }

  button.submit {
    float: right;
    font-family: arial;

    &:disabled {
      svg {
        color: #ccc;
        polygon {
          fill: #ddd
        }
      }
    }

    svg {
      color: #aaa;
      margin-top: 6px;
      polygon {
        fill: #ccc;
      }
    }
  }

  .input-div {
    position: absolute;
    background:white;
    width: 240px;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    border-color: #aaa;
    z-index: 10;
    margin-left: 10px;

    a, a:visited, a:active, a:hover {
      color: black;
      text-decoration: none;
    }

    span {
      display: block;
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 20px;

      &:first-child {
        margin-top: 2px;
      }

    }

    .close {
      text-align: center;
      padding: 10px;
    }
  }

  .side-text {
    font-size: 14px;
    line-height: 14px;
    font-weight: normal;
  }

  .status-text {
    text-align: center;
    margin-top: 10px;
  }

  th {
    vertical-align: top;
  }

  h1 {
    text-align: center;
    margin-top: 20px;
  }

  .language {
    position: absolute;
    top: 25px;
    right: 20px;
    span {
      margin-left: 15px;
    }
  }

  .slippery-warning {
    margin-left: 20px;
    font-style: italic;
  }

  .stop-data-route {
    white-space: nowrap;
    svg {
    }
  }

</style>
