function getFirstNonAlpha(str) {
  for (var i = 0; i<str.length;i++) {
    if (isNaN(str[i])) {
      return i;
    }
  }
  return str.length;
}

export { getFirstNonAlpha }
